import createStore from "unistore";

let store = createStore({
  showBottomTabs: false,
  loadUser: true,
  currentUser: null,
  selectedCountry: null,
  showSidebarMenu: false
});

let actions = store => ({
  showBottomTabs(state, showBottomTabs = false) {
    return { showBottomTabs };
  },
  setLoadUser(state, loadUser) {
    return { loadUser };
  },
  setCurrentUser(state, currentUser) {
    return { currentUser, loadUser: false };
  },
  setSelectedCountry(state, selectedCountry) {
    return { selectedCountry };
  },
  setShowSidebarMenu(state, showSidebarMenu) {
    return { showSidebarMenu };
  }
});

export { store, actions };
