import React, { Suspense } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./Router";
import { Provider } from "unistore/react";

import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./ui/Theme";
import SidebarDrawer from "./ui/SidebarDrawer";
import { store } from "lib/Store";
import "prevent-pull-refresh";

// import ReactGA from "react-ga";
// ReactGA.initialize("UA-148475697-1");

const App = () => {
  return (
    <Suspense fallback={<LinearProgress variant="query" />}>
      <Provider store={store}>
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRouter />
            <SidebarDrawer />
          </ThemeProvider>
        </Router>
      </Provider>
    </Suspense>
  );
};
export default App;
