import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#C13E4C"
    },
    secondary: {
      main: "#000000"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#f9f9f9"
    }
  },
  shape: {
    borderRadius: 20
  }
});

export default theme;
