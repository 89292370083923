import React from "react";
import { Route } from "react-router-dom";
import Cookies from "js-cookie";
import PrivateRoute from "./PrivateRoute";
import { connect } from "unistore/react";
import { actions } from "lib/Store";
import { withTracker } from "lib/GoogleAnalytics";

/**
 * Guest Pages
 */
const Login = React.lazy(() => import("./pages/Login"));

/**
 * Private/Auth pages
 */
const Home = React.lazy(() => import("./pages/Home"));
const ListTahanan = React.lazy(() => import("./pages/ListTahanan"));
const ShowTahanan = React.lazy(() => import("./pages/ShowTahanan"));

function AppRouter(props) {
  let userData = Cookies.get("userData");
  if (userData) {
    props.setLoadUser(false);
    props.setCurrentUser(JSON.parse(userData));
  } else {
    props.setLoadUser(false);
  }

  return (
    <>
      <Route path="/" exact component={withTracker(Login)} />
      <PrivateRoute path="/home" exact component={withTracker(Home)} />
      <PrivateRoute
        path="/listTahanan/:year"
        exact
        component={withTracker(ListTahanan)}
      />
      <PrivateRoute
        path="/showTahanan/:idTahanan"
        exact
        component={withTracker(ShowTahanan)}
      />
    </>
  );
}

export default connect("", actions)(AppRouter);
