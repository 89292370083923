import React from "react";
import { Route, Redirect } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "unistore/react";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser, loadUser } = rest;

  if (loadUser) {
    return (
      <Box
        height={"100vh"}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps =>
        !!currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/"} />
        )
      }
    />
  );
};

export default connect("currentUser,loadUser")(PrivateRoute);
