import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { connect } from "unistore/react";
import { actions } from "lib/Store";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const SidebarDrawer = props => {
  const closeDrawer = () => {
    props.setShowSidebarMenu(false);
  };

  const onLogout = () => {
    Cookies.remove("userData");
    props.setCurrentUser(null);
  };

  return (
    <Drawer open={props.showSidebarMenu} onClose={closeDrawer}>
      <div
        onClick={closeDrawer}
        onKeyDown={closeDrawer}
        role="presentation"
        style={{ width: 250 }}
      >
        <List>
          <ListItem button component={Link} to="/home">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>
        </List>
        <Box width={"100%"} position="absolute" bottom={0}>
          <List>
            <Divider />
            <ListItem button onClick={onLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={"Sign Out"} />
            </ListItem>
          </List>
        </Box>
      </div>
    </Drawer>
  );
};

export default connect("showSidebarMenu", actions)(SidebarDrawer);
